<template>
  <div class="py-2">
    <div v-if="fundraiser.rewards.length">
      <div class="font-semibold text-text-alternate-4">
        <div
          class="rounded border border-border-primary p-4 my-4 relative"
          v-for="reward in fundraiser.rewards"
          :key="reward.id"
        >
          <div class="flex flex-wrap justify-between items-center mb-2">
            <p>
              <span class="text-sm"
                >₦{{ reward.blessingAmount.toLocaleString() }}</span
              >
              <span class="text-xs text-text-primary"> or more</span>
            </p>
          </div>
          <h3 class="text-text-primary text-xs my-2">Reward</h3>
          <p class="text-xs">
            {{ reward.reward }}
          </p>
        </div>
      </div>
      <div class="text-text-alternate-1 text-2xs leading-4 my-5 font-medium">
        <h2 class="my-2">Disclaimer</h2>
        <p>
          Rewards fulfilment is solely at the discretion of the Organiser of
          fundraiser. Organisers are expected to fulfil their reward promises,
          but Blessmi is not involved in the reward process and does not
          guarantee fulfilment.
        </p>
        <p>
          If you have not received your reward as promised, you may organiser or
          fundraiser for immediate dismissal from the platform.
        </p>
      </div>
    </div>
    <div
      class="flex flex-col items-center text-center px-6 pt-6 pb-10 text-text-primary"
      v-else
    >
      <CommentIcon class="my-8" />
      <h3 class="py-2 font-semibold text-lg">No Rewards</h3>
      <p class="px-5 py-2 text-xs">This fundraiser has no rewards.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rewards',

  props: ['fundraiser', 'authUser'],
};
</script>
